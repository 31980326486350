/* HomePage.css */
.home-container {
    margin: 0 auto;
    position: fixed;
    top: 0;
    padding-top: 10px; /* Adjusted to ensure enough space above */
    text-align: center;
    overflow: visible; /* Prevent horizontal scroll */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100vh;
  }

  .scroll-div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 5000px;
  }
  
  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto; /* Vertically space out the carousel */
    gap: 30px; /* Adds space between carousel items */
  }
  
  .carousel-slide {
    /* flex: 0 0 auto; */
    max-width: 100%;
    opacity: 80%;
    transition-property: all;
    transition-duration: 400ms;
  }

  .focused {
    transform: scale(1.15);
    opacity: 100%;
  }
  
  .carousel-image {
    opacity: 0.5; /* Slightly see-through for inactive images */
    transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Specific style for the t-shirt base image */
.tshirt-base {
    max-height: 300px; /* Limit the height of the t-shirt images */
}

.tshirt-base.carousel-image.active {
    transform: scale(1.2); /* Scale up only the t-shirt */
    opacity: 1;
}
  
  /* Style arrows */
  .carousel-arrow {
    cursor: pointer;
    user-select: none;
    font-size: 2em; /* Size of the arrows */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  
  .carousel-arrow.left {
    left: 10px;
  }
  
  .carousel-arrow.right {
    right: 10px;
  }
  
  /* Add any additional styling for main heading and subheading below */
  .main-heading {
    font-size: 6em;
    margin-bottom: 0.5em; /* Adjust the margin as needed */
    color: #bbb;
    font-weight: 700;
  }
  
  .sub-heading {
    font-size: 3em;
    margin-bottom: 1em; /* Adjust the margin as needed */
    color: #bbb;
  }

/* Style for the container that will hold both the t-shirt and the QR code */
.tshirt-image-container {
  position: relative;
  cursor: pointer;
  width: auto; /* Adjust based on your image size */
  height: auto; /* Adjust based on your image size */
  display: inline-block; /* Align properly without taking full width */
}

.qr-code {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%) scale(1); /* Center the QR code on the t-shirt */
  width: 100px; /* Fixed width for QR code */
  height: 100px; /* Fixed height for QR code */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth transition for visibility and scaling */
}

.qr-code.carousel-image.active {
  transform: translate(-50%, -75%) scale(1.1); /* Scale up the QR code a bit when active */
  opacity: 1;
}

/* Style for the t-shirt image to ensure it fills the container */
.tshirt-base {
  display: block;
  width: 100%;
  height: auto;
}

  
.shirt-canvas {
  max-width: 80%;
}

.signed-in {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.signed-in > img {
  height: 30px;
  padding-right: 10px;
  object-fit: contain;
}

.signed-in > p {
  line-height: 0;
  margin: 0px;
  color: #bbb;
}

@keyframes rotate {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}

/* Apply the animation to the element */
.rotating-element {
  width: 100px;
  height: 100px;
  background-color: #3498db;
  animation: rotate 2s linear infinite;
}

@keyframes rotate3d {
  from {
    transform: rotate3d(0,1,0,0deg);
  }
  to {
    transform: rotate3d(0,1,0,360deg);
  }
}

.rotate-3d {
  animation: rotate3d 8s linear infinite, left-right;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  /* border: 3px solid blue; */
  position: relative;
  width: 40%;
}

.small-shirt {
  width: 150px;
  height: 150px;
  transition: transform 500ms ease-in-out;
  transition-delay: 800ms;
}

/* Pop shirt */
.pop-shirt {
  position: relative;
  transition: transform 800ms ease-in-out;
  /* border: 1px solid red; */
  width: 150px;
  height: 150px;
}

.transition {
  transition: transform 800ms ease-in-out;;
}

.grow {
  transform: scale(3);
}


.code {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  /* transform: rotate3d(-1, 2, 0, -15deg); */
  transition: transform 800ms ease-in-out;
}

.pop :nth-child(1){
  transform: rotate3d(-1, 2, 0, -15deg) translate3d(0, 0, -250px);
}

.pop :nth-child(2) {
  transform: rotate3d(-1, 2, 0, -15deg) translate3d(0, 0, 400px);
}

.pop :nth-child(3) {
  transform: rotate3d(-1, 2, 0, -15deg) translate3d(0, 0, 200px);
}

.pop :nth-child(4) {
  transform: rotate3d(-1, 2, 0, -15deg) translate3d(0, 0, 0px);
}

.code-1 {
  z-index: 30;
  width: 45px;
  height: 45px;
  top: 40px;
}

.code-2 {
  z-index: 20;
  width: 35px;
  height: 35px;
  top: 45px;
}

.code-3 {
  z-index: 10;
  width: 25px;
  height: 25px;
  top: 50px;
}

.hide {
  transform: rotate3d(0, 1, 0, 90deg);
  transition-delay: 0ms;
}

  
@media only screen and (max-width: 1000px) {
  .home-container {
    flex-direction: column;
  }

  .grid-container {
    width: 100%;
    transform: scale(.7);
  }
}

@media only screen and (max-width: 400px) {
  .small-shirt {
    width: 100px;
    height: 100px;
  }

  .pop-shirt {
    width: 100px;
    height: 100px;
  }

  .code-1 {
    z-index: 30;
    width: 30px;
    height: 30px;
    top: 29px;
  }
  
  .code-2 {
    z-index: 20;
    width: 23px;
    height: 23px;
    top: 32.5px;
  }
  
  .code-3 {
    z-index: 10;
    width: 16px;
    height: 16px;
    top: 36px;
  }

  .pop :nth-child(1){
    transform: rotate3d(-1, 2, 0, -15deg) translate3d(0, 0, -100);
  }
  
  .pop :nth-child(2) {
    transform: rotate3d(-1, 2, 0, -15deg) translate3d(0, 0, 150);
  }
  
  .pop :nth-child(3) {
    transform: rotate3d(-1, 2, 0, -15deg) translate3d(0, 0, 75);
  }
  
  .pop :nth-child(4) {
    transform: rotate3d(-1, 2, 0, -15deg) translate3d(0, 0, 0px);
  }
}