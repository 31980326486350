@keyframes process {
  0% {
    transform: rotate3d(1,0,0, 5deg);
  }
  25% {
    transform: rotate3d(0,1,0, 5deg);
  }
  50% {
    transform: rotate3d(-1,0,0, 5deg);
  }
  75% {
    transform: rotate3d(0,-1,0, 5deg);
  }
  100% {
    transform: rotate3d(1,0,0, 5deg);
  }
}

.container {
  position: relative;
}


.container:hover :nth-child(1){
  transform: rotate3d(1, 2, 0, 20deg) translate3d(0, 0, -250px);
  /* animation: process 8s linear infinite; */
  /* transform: scale(1.1); */
}

.container:hover :nth-child(2) {
  transform: rotate3d(1, 2, 0, 20deg) translate3d(0, 0, 500px);
}

.container:hover :nth-child(3) {
  transform: rotate3d(1, 2, 0, 20deg) translate3d(0, 0, 250px);
}

.container:hover :nth-child(4) {
  transform: rotate3d(1, 2, 0, 20deg) translate3d(0, 0, 0px);
}

