/* MintNFTPage.css */
.mint-container {
  margin: 0 auto;
  padding-top: 10px;
  max-width: 1200px;
  text-align: center;
  overflow: hidden;
}

.mint-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  gap: 30px;
}

.mint-item-container > p {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.mint-item {
  flex: 0 0 auto;
}

.mint-image {
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0.5; /* You can adjust as necessary */
  max-height: 300px;
}

.mint-image.active {
  transform: scale(1.2);
  opacity: 1;
}

/* Reuse the same styles for headings */
.main-heading {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.sub-heading {
  font-size: 1.5em;
  margin-bottom: 1em;
}

/* MintPage.css additional styles */

/* Button styling for a more professional look */
.mint-button {
  background-image: linear-gradient(to right, #1ddb20 0%, #1ddb89 100%); 
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  outline: none; /* Removes the outline to make it look cleaner */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Soft box shadow for depth */
}

/* Button hover state */
.mint-button:hover {
  /* background-color: #45a049; */
}

.disabled {
  background-image: none;
  background-color: gray;
  cursor: not-allowed !important;
}

/* Button active/focus state */
.mint-button:active,
.mint-button:focus {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

/* Button disabled state */
.mint-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

/* ... (existing styles) */

/* Styling for the minted T-shirt container */
.minted-tshirt-container {
  text-align: center;
  margin-top: 20px;
}

/* Styling for the minted T-shirt image */
.minted-tshirt-image {
  max-width: 100%; /* Ensures the image is responsive */
  height: auto; /* Maintains the aspect ratio */
  border: 1px solid #ddd; /* Gives the image a slight border */
  border-radius: 4px; /* Optionally round the corners */
  padding: 5px; /* Some padding around the image */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Styling for the minted T-shirt title */
.minted-tshirt-container h3 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.loader {
  border: 12px solid #f3f3f3; /* Light grey background */
  border-top: 12px solid #4CAF50; /* Match the mint button green color */
  border-radius: 50%;
  width: 80px; /* Reduced size */
  height: 80px; /* Keep it a perfect circle */
  animation: spin 2s linear infinite;
  margin: 20px auto; /* Center the loader, reduce margin for smaller size */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

