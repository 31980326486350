/* global.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Helvetica is closer to your design */
    background-color: #000000; /* Light grey background */
    color: #333; /* Dark grey text for better contrast */
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    background-color: #000000; /* Example background color */
  }
  
  nav ul li {
    padding: 20px;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #bbb; /* Dark text for better contrast */
    font-weight: bold;
  }
  
  /* Other global styles */
  