/* NavigationBar.css */
nav {
    background-color: transparent;
    width: 100%;
    height: 62px;
    box-sizing: border-box; /* Ensures padding is included in the width */
    position: fixed;
    top: 0;
    z-index: 20;
    white-space: nowrap;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0; /* Removes default margin */
    display: flex;
    justify-content: center;
}

nav li {
    margin: 0 15px; /* Adjust spacing between links */
}

nav a {
    text-decoration: none;
    font-size: 18px;
    color: black;
    font-weight: 400;
}

  
  /* Ensure that the body has no margin and padding to handle overall spacing */
  body {
    margin: 0;
    padding: 0;
  }
  
  /* Set the overall HTML and body to handle full width */
  html, body {
    width: 100%;
    overflow-x: hidden;
  }

  .spacer {
    height: 62px;
  }

  @media only screen and (max-width: 500px) {
    nav {
      height: 30px;
    }
    nav a {
      font-size: .8rem;
    }
    nav li {
      margin: 0 5px; /* Adjust spacing between links */
      padding: 5px !important;
    }
    .spacer {
      height: 30px;
    }
  }